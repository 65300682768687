import Vue from 'vue'

import { configureClient } from '@maxsystems/ui/api'
import UI, { vuetify } from '@maxsystems/ui/vue'

import { name, version } from '../package.json'
import App from './App'
import { withInstance } from './plugins/instance'
import VueRouter, { router } from './plugins/router'
import Vuex, { store } from './plugins/vuex'
import './plugins/sentry'
import './plugins/datadog'

configureClient({ name, version })

Vue.use(UI)
Vue.use(VueRouter)
Vue.use(Vuex)

Vue.prototype.$debug = message => console.log(`%c  path store  %c ${message} %c`, 'background:#35495e;padding:1px;border-radius:3px 0 0 3px;color:#fff', 'background:#2196f3;padding:1px;border-radius:0 3px 3px 0;color: #fff', 'background:transparent')

export default withInstance(new Vue({
  router,
  store,
  render: h => h(App),
  vuetify
}))
