import { datadogRum } from '@datadog/browser-rum'

if (process.env.DEPLOY_ENV === 'production') {
  datadogRum.init({
    applicationId: 'cee86723-7550-4879-a1aa-59dc25f715a2',
    clientToken: 'pubf15a3975a6a9aed3df1ee63716edc6ba',
    site: 'datadoghq.com',
    service: 'showroom-web-vuejs',
    env: 'max-production',
    version: process.env.SHA, // Used to identify the deployed version in Datadog.
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input'
  })
}
